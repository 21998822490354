import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import StyledPaperCard from "./StyledPaperCard";
import UpdateCurrentTeam from "./UpdateCurrentTeam";
import UpdateDisplayName from "./UpdateDisplayName";
import UpdateEmail from "./UpdateEmail";

export default function AccountPreferences({ userData }) {
  return (
    <StyledPaperCard>
      <Stack spacing={3}>
        <Typography variant="h4">Update Account Preferences</Typography>
        <UpdateEmail />
        <UpdateDisplayName />
        {userData.teamName ? <UpdateCurrentTeam userData={userData} /> : ""}
      </Stack>
    </StyledPaperCard>
  );
}
