import React, { useState } from "react";

import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import { apiSlice } from "../util/apiSlice";
import RosterTable from "./RosterTable";
import StyledPaperCard from "./StyledPaperCard";

export default function Roster() {
  const rosterResponse = apiSlice.useGetRosterQuery();
  const roster = rosterResponse.data;

  const [byUser, setByUser] = useState(true);
  const handleToggleByUser = (event, value) => {
    if (value !== null) {
      setByUser(value);
    }
  };

  return (
    <Stack spacing={3} padding={3}>
      <StyledPaperCard>
        <Typography variant="h4" gutterBottom>
          Users and Teams Roster
        </Typography>
        <Typography variant="h6" gutterBottom>
          Organize roster by:
        </Typography>
        <ToggleButtonGroup
          color="primary"
          value={byUser}
          exclusive
          onChange={handleToggleByUser}
        >
          <ToggleButton value={true}>User</ToggleButton>
          <ToggleButton value={false}>Team</ToggleButton>
        </ToggleButtonGroup>
      </StyledPaperCard>
      <RosterTable roster={roster} byUser={byUser} />
    </Stack>
  );
}
