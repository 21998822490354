import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import StyledPaperCard from "./StyledPaperCard";
import { B } from "./TextStyles";

export default function Rules() {
  return (
    <Stack spacing={2} padding={3} className="rules">
      <StyledPaperCard>
        <Typography variant="h4">The Rules of the Pool</Typography>
      </StyledPaperCard>
      <StyledPaperCard>
        <Typography variant="h6">
          <B>WINNERS Bracket and LOSERS Bracket</B>
        </Typography>
        <Typography variant="body1" component="div">
          <ol>
            <li>
              This Survivor Pool begins during Week One of the NFL regular
              season and continues through the 18 weeks of the regular season
              plus 4 rounds of playoffs, ending with the Super Bowl as the final
              round of Pool play.
            </li>
            <li>
              Each Player shall select one NFL team each week to win its game
              outright (point spreads and betting lines are NOT taken into
              consideration).
            </li>
            <li>
              All Players begin the season in the WINNERS Bracket. Players
              remain in the WINNERS Bracket as long as they continue to
              correctly pick a winning team each week. If a Player selects a
              team that loses or ties its game, Player will drop down to the
              LOSERS Bracket. Players that drop down to the LOSERS Bracket shall
              remain there until either a "RESET" (defined below) or the end of
              the Regular Season. At the end of the Regular Season, all Players
              are returned to the WINNERS Bracket for the Playoffs.
            </li>
            <li>
              RESET: Once all Players have lost at least one time during the
              regular season, this triggers a RESET and all Players are returned
              to the WINNERS Bracket in the following week. It is possible that
              the Pool could "reset" more than once during the regular season.
            </li>
          </ol>
        </Typography>
      </StyledPaperCard>
      <StyledPaperCard>
        <Typography variant="h6">
          <B>Scoring</B>
        </Typography>
        <Typography variant="body1" component="div">
          <ol start={5}>
            <li>
              If the NFL team selected by the Player wins in the week selected,
              then the Player is awarded three (3) points if they are in the
              WINNERS Bracket but only two (2) points if they are in the LOSERS
              Bracket.
            </li>
            <li>
              If a Player currently in the WINNERS Bracket picks an NFL team
              that loses or ties in the week selected, then the Player does not
              receive any points for that week and the Player falls to the
              LOSERS Bracket.
            </li>
            <li>
              If the NFL team selected by the Players ties in the week selected,
              this is considered a "loss" for purposes of the pool.
            </li>
          </ol>
        </Typography>
      </StyledPaperCard>
      <StyledPaperCard>
        <Typography variant="h6">
          <B>NFL Team Selections</B>
        </Typography>
        <Typography variant="body1" component="div">
          <ol start={8}>
            <li>
              Players shall select one NFL team to win each week. The deadline
              to submit picks each week is Friday at Midnight, Pacific time.
              However, if a Player chooses to select a team that is playing on a
              Thursday, the deadline to submit a Thursday night pick is Thursday
              at 3pm, Pacific time.
            </li>
            <li>
              The week of Thanksgiving, all picks will be due at midnight on
              Wednesday, 11/22/2023 from all Players.
            </li>
            <li>
              Each NFL Team may only be selected once by each Player during the
              regular season. Once an NFL Team is selected by a Player, it may
              not be selected again by that Player until the playoffs when all
              playoff teams are made available to all Players.
            </li>
            <li>
              The process is repeated at the beginning of the playoffs with all
              playoff teams being available to be picked by all Players,
              regardless of whether a team was previously used in the regular
              season. Every Player is restored to the WINNERS bracket at the
              beginning of the playoffs. Each NFL Team may only be selected once
              by each Player during the playoffs. If an NFL Team selected by a
              Player during the playoffs loses, then that Player shall receive
              zero points and drop down to the LOSERS bracket.
            </li>
            <li>
              There will likely be ties in the standings for money spots at the
              end of the season. To break ties, each Player must submit a guess
              of the total number of points scored by both teams in the Super
              Bowl game to the nearest tenth of a point. There is no difference
              between guessing more or less than the actual total. The delta
              between the guess and the actual total is all that matters.
              Failing to pro-actively make a points guess will result in a guess
              of zero points scored in the Super Bowl. If a tie for any place
              still exists after factoring in the points guess, a coin flip will
              break the tie.
            </li>
          </ol>
        </Typography>
      </StyledPaperCard>
      <StyledPaperCard>
        <Typography variant="h6">
          <B>Default Selections</B>
        </Typography>
        <Typography variant="body1" component="div">
          <ol start={13}>
            <li>
              If a Player fails to submit an NFL Team pick prior to the
              selection deadline in any week, the Player shall be assigned a
              "Default Pick".
            </li>
            <li>
              The Default Pick shall be the biggest betting favorite not yet
              used by the Player. The point spread favorites will be prioritized
              according to the point spreads listed on FANDUEL each week on on
              Friday evening, Pacific time. The spreads as of Wednesday evening
              will be used to determine the default picks during the week of
              Thanksgiving.
            </li>
            <li>
              Any disputes regarding point spreads used to determine Default
              Picks will be settled at the sole discretion of the Committee.
            </li>
          </ol>
        </Typography>
      </StyledPaperCard>
      <StyledPaperCard>
        <Typography variant="h6">
          <B>Payouts</B>
        </Typography>
        <Typography variant="body1" component="div">
          <ol start={16}>
            <li>
              At the end of the season, the Pool will pay prize money to the top
              finishers. Historically, we make payments to the top 10-15% of
              finishers as well as the three (3) Players who accumulate the
              fewest points and finish at the bottom of the standings. As the
              prize money is dependent on the number of pool participants, the
              prize list will be published during the regular season.
            </li>
          </ol>
        </Typography>
      </StyledPaperCard>
    </Stack>
  );
}
