import React from "react";

import Stack from "@mui/material/Stack";

import { useOutletContext } from "react-router-dom";

import AccountPreferences from "./AccountPreferences";
import AddTeamForms from "./AddTeamForms";
import AllowNewUsersForm from "./AllowNewUsersForm";
import SetReminders from "./SetReminders";

export default function Settings() {
  const { userData } = useOutletContext();

  return (
    <Stack spacing={2} padding={3}>
      <SetReminders userData={userData} />
      <AccountPreferences userData={userData} />
      <AddTeamForms userData={userData} />
      {userData.pendingUsers ? <AllowNewUsersForm userData={userData} /> : ""}
    </Stack>
  );
}
