import React, { useState } from "react";

import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useOutletContext } from "react-router-dom";

import { apiSlice } from "../util/apiSlice";
import {
  getByeTeams,
  getDefaultPicks,
  getThursdayTeams,
  weekModes,
} from "../util/constants";
import ConfirmSubmitButton from "./ConfirmSubmitButton";
import ConfirmTeamModal from "./ConfirmTeamModal";
import Loading from "./Loading";
import PickTitleCard from "./PickTitleCard";
import SelectPick from "./SelectPick";
import StyledPaperCard from "./StyledPaperCard";
import SuperBowlScore from "./SuperBowlScore";
import TeamBanner from "./TeamBanner";
import TeamNameSelect from "./TeamNameSelect";
import TeamOdds from "./TeamOdds";
import { B } from "./TextStyles";

export default function Pick() {
  const { userData } = useOutletContext();

  const weekResponse = apiSlice.useGetWeekQuery();
  const week = weekResponse.data;

  const teamsStatsResponse = apiSlice.useGetTeamsStatsQuery();
  const teamsStats = teamsStatsResponse.data;

  const defaultPicks = getDefaultPicks(teamsStats, week, userData);

  const [selectedTeam, setSelectedTeam] = useState(
    userData.currentPick || null,
  );

  const [severity, setSeverity] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onConfirmTeamClick = () => {
    if (userData.currentPick) {
      handleOpen();
      return false;
    } else {
      return true;
    }
  };

  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value);
  };

  if (week && week.N < 0) {
    return (
      <StyledPaperCard>
        <Stack spacing={2}>
          <Typography variant="h4" gutterBottom>
            <TeamNameSelect userData={userData} /> Pick
          </Typography>
          <Typography variant="h5" pt={2}>
            No more picks available. Come back next year for more!!
          </Typography>
        </Stack>
      </StyledPaperCard>
    );
  }

  const thurTeams = getThursdayTeams(teamsStats);
  const isThurPick = thurTeams.includes(userData.currentPick);

  if (
    week &&
    (week.mode === weekModes.LOCK_ALL ||
      (week.mode === weekModes.LOCK_THUR &&
        thurTeams &&
        thurTeams.includes(userData.currentPick)))
  ) {
    return (
      <Stack spacing={3} padding={3} sx={{ display: "flex" }}>
        <PickTitleCard
          userData={userData}
          week={week}
          teamsStats={teamsStats}
          isThurPick={isThurPick}
        />
      </Stack>
    );
  }

  const byeTeams = getByeTeams(teamsStats);
  return (
    <Grid container spacing={3} padding={3} alignItems="stretch">
      <Grid item xs={12} sm={12} md={12}>
        <PickTitleCard
          userData={userData}
          week={week}
          teamsStats={teamsStats}
          isThurPick={isThurPick}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <StyledPaperCard sx={{ width: "auto" }}>
          <Stack>
            <Typography variant="h5" gutterBottom noWrap>
              MAKE YOUR PICK
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            {!byeTeams ? (
              <Loading />
            ) : byeTeams === "error" || thurTeams === "error" ? (
              <Alert severity="error" sx={{ width: "fit-content" }} />
            ) : (
              <>
                <SelectPick
                  userData={userData}
                  week={week}
                  byeTeams={byeTeams}
                  thurTeams={thurTeams}
                  onChange={handleTeamChange}
                  value={selectedTeam}
                />
                <ConfirmSubmitButton
                  data={selectedTeam}
                  postTo="/update-pick"
                  what="Pick"
                  mutationHook={apiSlice.useSetNewDataAndReloadUserDataMutation}
                  onClick={onConfirmTeamClick}
                  disabled={
                    userData.currentPick &&
                    userData.currentPick === selectedTeam
                  }
                  variant="contained"
                  severityOverride={severity}
                  sx={{ width: 280, ml: 0 }}
                />
              </>
            )}
            <ConfirmTeamModal
              onClose={handleClose}
              open={open}
              oldTeam={userData.currentPick}
              newTeam={selectedTeam}
              setSeverity={setSeverity}
            />
            {week && week.name === "Super Bowl" ? (
              <SuperBowlScore userData={userData} />
            ) : (
              ""
            )}
          </Stack>
        </StyledPaperCard>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <StyledPaperCard
          sx={{ width: "auto", minWidth: "fit-content", height: "100%" }}
        >
          <Typography variant="h5" gutterBottom>
            TEAMS NOT AVAILABLE
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <Typography component="div">
            {thurTeams === "error" || byeTeams === "error" ? (
              <Alert severity="error" sx={{ width: "fit-content" }} />
            ) : (
              <>
                <B>Previously Selected:</B> {userData.usedPicks.join(", ")}
                {byeTeams && byeTeams.length > 0 ? (
                  <>
                    <br />
                    <B>Not Playing:</B> {byeTeams.join(", ")}
                  </>
                ) : (
                  ""
                )}
                {week && week.mode === weekModes.LOCK_THUR && thurTeams ? (
                  <>
                    <br />
                    <B>Thursday Game{thurTeams.length > 2 ? "s" : ""}:</B>{" "}
                    {thurTeams.join(", ")}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Typography>
        </StyledPaperCard>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <StyledPaperCard>
          <Stack sx={{ gap: 2 }}>
            <Typography variant="h5">QUICK PICKS*</Typography>
            <Divider sx={{ marginBottom: 1 }} />
            {!defaultPicks ? (
              <Loading />
            ) : defaultPicks === "error" ? (
              <Alert severity="error" sx={{ width: "fit-content" }} />
            ) : (
              defaultPicks.map((team) => (
                <Stack
                  direction="row"
                  key={team}
                  sx={{ gap: 2, flexWrap: "wrap" }}
                >
                  <TeamBanner
                    team={team}
                    onClick={() => setSelectedTeam(team)}
                    isButton={true}
                  />
                  <TeamOdds team={team} teamsStats={teamsStats} />
                </Stack>
              ))
            )}
            <Typography pt={5} sx={{ paddingTop: 1 }}>
              *All odds are taken from fanduel, early lines.
            </Typography>
          </Stack>
        </StyledPaperCard>
      </Grid>
    </Grid>
  );
}
