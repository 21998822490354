import React from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { teamNames } from "../util/constants";

export default function TeamBanner({ team, sx, isButton, ...otherProps }) {
  const Comp = isButton ? Button : Paper;
  return (
    <Comp
      {...otherProps}
      sx={{
        backgroundColor: `team.${team}.primary`,
        maxWidth: "fit-content",
        "&:hover": {
          backgroundColor: `team.${team}.primary`,
          opacity: isButton ? 0.7 : 1,
        },
        ...sx,
      }}
    >
      <Typography variant="h4" color="white" padding={2} align="center">
        {teamNames[team].toUpperCase()}
      </Typography>
    </Comp>
  );
}
